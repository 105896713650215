import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const IsCopyRequest = props => {

    const { onChange, value } = props;

    const handleChange = React.useCallback(() => onChange(!value), [onChange, value]);

    return <FormGroup check>
        <Label check>
            <Input type="checkbox" id={props.id} checked={value || false} onChange={handleChange} disabled={props.disabled}/>{' '}
            {props.label}<br /><br />
        </Label>
    </FormGroup>
}

IsCopyRequest.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    disabled: PropTypes.bool
}



export default React.memo(IsCopyRequest);