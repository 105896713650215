import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "@isolutions/branding/assets/css/theme.min.css";

document.addEventListener("DOMContentLoaded", function () {
    const attachUiListeners = () => {
        const courseTypeUltra = document.getElementById("isUltraCourse-ultra");
        const courseTypeOriginal = document.getElementById("isUltraCourse-original");
        const isCopyCheckbox = document.getElementById("isCopy");
        const personalUseOption = document.querySelector('#purpose option[value="PERSONALUSE"]');

        if (!courseTypeUltra || !courseTypeOriginal || !isCopyCheckbox || !personalUseOption) {
            setTimeout(attachUiListeners, 100);
            return;
        }

        const updateUltraFromOriginal = () => {
            const isUltraFromOriginal = courseTypeUltra.checked && isCopyCheckbox.checked;
            personalUseOption.textContent = isUltraFromOriginal ? "Personal use / Sandbox" : "Personal use";
        };

        courseTypeUltra.addEventListener("change", updateUltraFromOriginal);
        courseTypeOriginal.addEventListener("change", updateUltraFromOriginal);
        isCopyCheckbox.addEventListener("change", updateUltraFromOriginal);
    };
    attachUiListeners();
});

/* TODO remove this when updated to node 14 on team city */
if (!Array.prototype.flatMap) {
  function flatMap(f, ctx) {
    return this.reduce((r, x, i, a) => r.concat(f.call(ctx, x, i, a)), []);
  }
  // eslint-disable-next-line no-extend-native
  Array.prototype.flatMap = flatMap;
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
