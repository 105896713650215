import PropTypes from "prop-types"
import capitalize from "capitalize";
import React from "react"
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import NoticeArea from "../../../../../NoticeArea";
import { Notice } from "../../../../../Store";


const Confirmation = (props) => {

    const { action, handleAction, isOpen, onClose, children, disabled, suppressUndoWarning } = props;
    
    const { dismiss } = React.useContext(Notice);

    const onOpen = () => {
        if (isOpen) {
            dismiss();
        }
    };
    React.useEffect(onOpen, [dismiss, isOpen])

    return <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader>{action && capitalize(action)} confirmation</ModalHeader>
        <ModalBody>
            <NoticeArea/>
            <p>Are you sure you want to {action?.toLowerCase()} this request?</p>
            {!suppressUndoWarning && <Alert color="warning">
                This cannot be undone!
            </Alert>
            }
            { children }
        </ModalBody>
        <ModalFooter>
            <Button type="button" color="secondary" onClick={onClose}>Cancel</Button>
            <Button type="submit" color="danger" disabled={disabled} onClick={handleAction}>Confirm</Button>
        </ModalFooter>
    </Modal>
}

Confirmation.propTypes = {
    disabled: PropTypes.bool,
    action: PropTypes.string,
    handleAction: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    suppressUndoWarning: PropTypes.bool
}

export default React.memo(Confirmation);