import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { BsFillPlusCircleFill as Plus } from "react-icons/bs";
import { Notice } from "../../../../Store";
import NoticeArea from "../../../../NoticeArea";
import PaginationTable, { PaginationColumn } from "../../../../PaginationTable";

const ProgrammeCourseList = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { info } = useContext(Notice);

  const { path } = props;

  const alertFromLocationState = () => {
    if (location?.state?.type === "PROGRAMME") {
      setTimeout(() => {
        info(
          <React.Fragment>
            Thank you for using this service. Your request for{" "}
            <strong>{location.state.data.courseId}</strong> has been submitted.
            Please refer to this table for the status of this request.
          </React.Fragment>
        );
      }, 200);
    }
  };
  React.useEffect(alertFromLocationState, [info, location]);

  const handleRequestClick = React.useCallback(
    () => history.push(`${path}/new`),
    [history, path]
  );

  return (
    <React.Fragment>
      <h1>Programme courses you requested</h1>
      <NoticeArea />
      <PaginationTable
        url="/requests?courseType=programme"
        rowKey={(request) => `${request.courseType}-${request.id}`}
        emptyMessage="No programme course requests"
      >
        <PaginationColumn
          cell={(request) =>
            new Date(request.requestDate).toLocaleString("en-GB")
          }
        >
          Requested Date
        </PaginationColumn>
        <PaginationColumn
          cell={(request) => {
            switch (request.state) {
              case 0:
                return <Badge color="info">Pending</Badge>;
              case 1:
                return <Badge color="danger">Rejected</Badge>;
              case 2:
                return <Badge color="success">Approved</Badge>;
              default:
                return null;
            }
          }}
        >
          Status
        </PaginationColumn>
        <PaginationColumn cell={(request) => request.courseId}>
          Course ID
        </PaginationColumn>
        <PaginationColumn cell={(request) => request.assignedTo}>
          Assigned To
        </PaginationColumn>
        <PaginationColumn cell={(request) => request.decisionReason}>
          Decision Reason
        </PaginationColumn>
        <PaginationColumn cell={(request) => request.decisionDate}>
          Decision Date
        </PaginationColumn>
      </PaginationTable>
      <br />
      <span className="float-right">
        <Button color="default" onClick={handleRequestClick}>
          <Plus /> Request a new programme course
        </Button>
      </span>
    </React.Fragment>
  );
};

ProgrammeCourseList.propTypes = {
  path: PropTypes.string.isRequired,
};

export default React.memo(ProgrammeCourseList);
