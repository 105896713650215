import PropTypes from "prop-types";
import Joi from "joi";
import React from "react"
import { FormError } from "..";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import { Collapse, FormGroup, Input, Label } from "reactstrap";
import { withAbort } from "../../../util";

/** 
 * @typedef DepartmentTemplateModel
 * @property {String} code
 * @property {String} name
 */

const departmentTemplateSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required()
});

/**
 * Validate the templates array
 *
 * @param {*} templates
 * @return {Array<DepartmentTemplateModel>} 
 */
const validateTemplates = templates => {
    const result = Joi.array().items(departmentTemplateSchema).validate(templates);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Templates Response", result);
    }
    return templates;
}

const DepartmentTemplates = props => {

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    /** @type {[Array<DepartmentTemplateModel>, React.Dispatch<Array<DepartmentTemplateModel>>]} */
    const [templates, setTemplates] = React.useState(null);

    /** @type {[DepartmentTemplateModel, React.Dispatch<DepartmentTemplateModel>]} */
    const [template, setTemplate] = React.useState(null);

    const { url, onChange, code, value, onReady } = props;
    const initialize = () => withAbort(signal => {
        const stopWait = waitFor("INIT_DEPARTMENT_TEMPLATES");
        fetchApi(url, { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateTemplates)
                        .then(setTemplates)
                } else {
                    throw new FormError("Could not contact Templates API");
                }
            })
            .then(() => onReady && onReady())
            .catch(handleError)
            .finally(stopWait)
    });
    React.useEffect(initialize, [fetchApi, handleError, onReady, url, waitFor]);

    const updateTemplate = () => setTemplate(templates?.find(temp => temp.code === code) || null);
    React.useEffect(updateTemplate, [code, templates])

    const handleOnChange = React.useCallback(() => onChange(Boolean(value) ? null : template), [onChange, template, value]);

    return <Collapse isOpen={Boolean(code)}>
        <FormGroup check>
            <Label for={props.id} check>
                <Input type="checkbox" id={props.id} disabled={!Boolean(code) || props.disabled} checked={Boolean(value)} onChange={handleOnChange} />{' '}
                Do you want to use <strong>{template?.name}?</strong><br />
            </Label>
        </FormGroup>
    </Collapse>
}

DepartmentTemplates.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    value: PropTypes.object,
    code: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onReady: PropTypes.func
}

export { departmentTemplateSchema };
export default React.memo(DepartmentTemplates);
