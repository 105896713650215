import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";
import { Authentication, ErrorHandler, Notice, Waiting } from "../../Store";
import { HandledError } from "../../Store";

const AssignedTo = props => {

    const { handleError } = React.useContext(ErrorHandler);
    const { user, fetchApi } = React.useContext(Authentication);
    const { waitFor } = React.useContext(Waiting);
    const { danger } = React.useContext(Notice);
    const { assignedTo, onChange, postUrl } = props;

    const handleOnClick = React.useCallback(() => {
         const stopWait = waitFor("ASSIGN_SELF");
            fetchApi(postUrl, { method: "POST" })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                            .then(result => onChange(result.assignedTo));
                    }
                    else if (response.status === 404) {
                        danger("This request does not exist - cannot assign.")
                    }
                    else {
                        throw new HandledError("Could not contact Requests API");
                    }
                })
                .catch(handleError)
                .finally(stopWait);
        }, [danger, fetchApi, handleError, waitFor, onChange, postUrl]);
    


    if (assignedTo) {
        if (user?.username === assignedTo) {
            return <p>This request is assigned to you.</p>
        }
        else {
            return <p>
                This request is assigned to <strong>{assignedTo}</strong>&nbsp;
                <Button className="float-right" color="primary" size="sm" onClick={handleOnClick}>Assign to me</Button>
            </p>
        }
    }
    else {
        return <p>
            This request is not assigned&nbsp;
            <Button className="float-right" color="primary" size="sm" onClick={handleOnClick}>Assign to me</Button>
        </p>
    }
}

AssignedTo.propTypes = {
    assignedTo: PropTypes.string,
    postUrl: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}



export default React.memo(AssignedTo);