import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const IsUltraCourse = props => {

    const { onChange } = props;

    const handleChange = React.useCallback(e => onChange(e.target.value === "ultra"), [onChange]);

    return <FormGroup tag="fieldset">
        <Label>{props.label}</Label>
        <FormGroup check>
            <Label check>
                <Input type="radio" id={`${props.id}-ultra`} value="ultra" onChange={handleChange} checked={props.value === true} disabled={props.disabled} invalid={Boolean(props.error)} />{' '}
                Ultra
            </Label>
        </FormGroup>
        <FormGroup check>
            <Label check>
                <Input type="radio" id={`${props.id}-original`} value="normal" onChange={handleChange} checked={props.value === false} disabled={props.disabled} invalid={Boolean(props.error)} />{' '}
                Original
            </Label>
        </FormGroup>
        <small className="text-danger" id={`${props.id}-error`}>{props.error}</small>
    </FormGroup>
}

IsUltraCourse.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string
}

export default React.memo(IsUltraCourse);
