import PropTypes from "prop-types";
import React from "react"
import { Tooltip } from "reactstrap";
import { BiHelpCircle } from 'react-icons/bi';


const HelpIcon = props => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return <React.Fragment>
        <BiHelpCircle id={props.id} />
        <Tooltip style={{fontFamily:"Verdana, Geneva, sans-serif"}} placement="right" target={props.id} isOpen={tooltipOpen} toggle={toggleTooltip}>
            {props.children}
        </Tooltip>
    </React.Fragment>
}

HelpIcon.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

export default React.memo(HelpIcon);


