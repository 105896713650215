import React from "react";
import PropTypes from "prop-types";
import InputPrependAppend from "../InputPrependAppend";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import Joi from "joi";
import { Button, Collapse, Input } from "reactstrap";
import { FormError } from "..";
import { withAbort } from "../../../util";

/** 
 * @typedef DepartmentModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 */

/** 
 * @typedef SchoolModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 * @property {Array<DepartmentModel>} departments 
 */

/** 
 * @typedef FacultyModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 * @property {Array<SchoolModel>} schools 
 */

const departmentSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional()
});

const schoolSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional(),
    departments: Joi.array().items(departmentSchema).optional()
});

const facultySchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional(),
    schools: Joi.array().items(schoolSchema).allow(null).optional(),
});

/**
 * Validate the faculties array
 *
 * @param {*} faculties
 * @return {Array<FacultyModel>} 
 */
const validateFaculties = faculties => {
    const result = Joi.array().min(1).items(facultySchema).required().validate(faculties);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Faculties Response", result);
    }
    return faculties;
}

/**
 * Convert a Faculty or School to a Department
 *
 * @param {FacultyModel | SchoolModel} obj
 * @returns {DepartmentModel}
 */
const toDepartment = obj => ({
    code: obj.code,
    name: obj.name,
    templateCode: obj.templateCode || null
});

const findFaculty = (faculties, code) => {
    return faculties.find(faculty => code === faculty.code) || null;
}

const findSchool = (schools, code) => {
    return schools.find(school => code === school.code) || null;
}

const findDepartment = (departments, code) => {
    return departments.find(department => code === department.code) || null;
}

const initialSelectionState = {
    faculty: null,
    school: null,
    department: null,
    facultyWide: false,
    schoolWide: false
}

const Departments = props => {

    const { url, onChange, value, onReady, onResetComplete, shouldReset } = props;

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    /** @type {[Array<FacultyModel>, React.Dispatch<Array<FacultyModel>>]} */
    const [faculties, setFaculties] = React.useState(null);

    const [selectionState, setSelectionState] = React.useState(initialSelectionState);

    const handleChange = React.useCallback(state => {
        if (state.facultyWide && Boolean(state.faculty)) {
            onChange(toDepartment(state.faculty));
        } else if (state.schoolWide && Boolean(state.school)) {
            onChange(toDepartment(state.school));
        } else if (Boolean(state.department)) {
            onChange(state.department);
        } else {
            onChange(null);
        }
        return state;
    }, [onChange])

    const setSelectedFaculty = React.useCallback(faculty => setSelectionState(prev => {
        const nextState = {
            ...prev,
            faculty,
            school: null,
            department: null,
            facultyWide: !Boolean(faculty?.schools?.length > 0)
        };
        return handleChange(nextState);
    }), [handleChange]);

    const setSelectedSchool = React.useCallback(school => setSelectionState(prev => {
        const nextState = {
            ...prev,
            school,
            department: null,
            schoolWide: !Boolean(school?.departments?.length > 0)
        };
        return handleChange(nextState);
    }), [handleChange]);

    const setSelectedDepartment = React.useCallback(department => setSelectionState(prev => {
        const nextState = {
            ...prev,
            department
        };
        return handleChange(nextState);
    }), [handleChange]);

    const toggleFacultyWide = React.useCallback(() => setSelectionState(prev => {
        const nextState = {
            ...prev,
            facultyWide: !prev.facultyWide
        };
        return handleChange(nextState);
    }), [handleChange]);

    const toggleSchoolWide = React.useCallback(() => setSelectionState(prev => {
        const nextState = {
            ...prev,
            schoolWide: !prev.schoolWide
        };
        return handleChange(nextState);
    }), [handleChange]);

    const hasSchools = Boolean(selectionState.faculty?.schools?.length > 0);
    const hasDepartments = Boolean(selectionState.school?.departments?.length > 0);

    const initialize = () => withAbort(signal => {
        const stopWait = waitFor("INIT_DEPARTMENTS");
        fetchApi(url, { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateFaculties)
                        .then(facultiesTemp => {
                            setFaculties(facultiesTemp);
                        })
                } else {
                    throw new FormError("Could not contact Faculties API");
                }
            })
            .then(() => onReady && onReady())
            .catch(handleError)
            .finally(stopWait)
    });
    React.useEffect(initialize, [fetchApi, handleError, onReady, url, waitFor]);

    const handleValueChange = () => {
        if (Boolean(faculties) && Boolean(value)) {
            const allSchools = faculties.flatMap(faculty => faculty.schools);
            const allDepartments = allSchools.flatMap(school => school.departments);

            const department = allDepartments.find(department => department.code === value?.code) || null;
            const school = allSchools.find(school => school.code === value?.code || school.departments?.includes(department)) || null;
            const faculty = faculties.find(faculty => faculty.code === value?.code || faculty.schools?.includes(school)) || null;
            const facultyWide = school === null && department === null;
            const schoolWide = school !== null && department === null;
            setSelectionState({
                department,
                school,
                faculty,
                facultyWide,
                schoolWide
            })
        }
    }
    React.useEffect(handleValueChange, [faculties, value]);

    const handleReset = () => {
        if (Boolean(shouldReset) && Boolean(onResetComplete)) {
            setSelectionState(initialSelectionState);
            onResetComplete();
        }
    }
    React.useEffect(handleReset, [shouldReset, onResetComplete]);

    const handleSelectedOnFacultyChange = React.useCallback(value => setSelectedFaculty(findFaculty(faculties, value)), [faculties, setSelectedFaculty]);
    const handleSelectedOnSchoolChange = React.useCallback(value => setSelectedSchool(findSchool(selectionState.faculty?.schools, value)), [selectionState.faculty?.schools, setSelectedSchool]);
    const handleSelectedOnDepartmentChange = React.useCallback(value => setSelectedDepartment(findDepartment(selectionState.school?.departments, value)), [selectionState.school?.departments, setSelectedDepartment]);

    return <React.Fragment>
        <Input hidden readOnly type="text" id={props.id} value={props.value?.code || ""} />

        <InputPrependAppend
            type="select"
            label="Primary faculty"
            id={`${props.id}-faculty`}
            value={selectionState.faculty?.code || ""}
            onChange={handleSelectedOnFacultyChange}
            error={hasSchools ? null : props.error}
            disabled={props.disabled}
            prepend={
                <Button
                    type="button"
                    title="take the current selected faculty as your department selection"
                    aria-label="take the current selected faculty as your department selection"
                    outline={!selectionState.facultyWide}
                    color={selectionState.facultyWide ? "success" : "secondary"}
                    disabled={!hasSchools || props.disabled}
                    onClick={toggleFacultyWide}>
                    Faculty Wide?
                </Button>
            }
            help="To start, select a faculty.  Then select either 'Faculty Wide?' to link to the faculty itself, or select a school within that faculty.  Similarly, if the school has sub-departments then select either 'School Wide?' or a department."
        >
            <option disabled value=""> -- select an option -- </option>
            {faculties?.map(faculty =>
                <option key={faculty.code} value={faculty.code}>{faculty.name}</option>
            )}
        </InputPrependAppend>
        <Collapse isOpen={!selectionState.facultyWide && hasSchools}>
            <InputPrependAppend type="select" label="Primary school" id={`${props.id}-school`} value={selectionState.school?.code || ""} onChange={handleSelectedOnSchoolChange} error={hasDepartments ? null : props.error} disabled={props.disabled} prepend={
                <Button
                    type="button"
                    title="take the current selected school as your department selection"
                    aria-label="take the current selected school as your department selection"
                    outline={!selectionState.schoolWide}
                    color={selectionState.schoolWide ? "success" : "secondary"}
                    disabled={!hasDepartments || props.disabled}
                    onClick={toggleSchoolWide}>
                    School Wide?
                </Button>
            }>
                <option disabled value=""> -- select an option -- </option>
                {selectionState.faculty?.schools?.map(school =>
                    <option key={school.code} value={school.code}>{school.name}</option>
                )}
            </InputPrependAppend>
        </Collapse>
        <Collapse isOpen={!selectionState.schoolWide && hasDepartments}>
            <InputPrependAppend type="select" label="Primary department" id={`${props.id}-department`} value={selectionState.department?.code || ""} disabled={props.disabled} error={props.error} onChange={handleSelectedOnDepartmentChange}>
                <option disabled value=""> -- select an option -- </option>
                {selectionState.school?.departments?.map(department =>
                    <option key={department.code} value={department.code}>{department.name}</option>
                )}
            </InputPrependAppend>
        </Collapse>
    </React.Fragment>
}

Departments.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    onReady: PropTypes.func,
    shouldReset: PropTypes.bool,
    onResetComplete: PropTypes.func
}

export { departmentSchema };
export default React.memo(Departments);
