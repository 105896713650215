import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

const DepartmentsDropdown = props => {

    const { faculties, onChange, value } = props;

    return <React.Fragment>
        <Input
            type="select"
            onChange={onChange}
            value={value}
            title="Department filter"
            aria-label="Department filter" >
            <option value="">All faculties/schools/departments</option>
            {
                faculties.map(f =>
                    <React.Fragment>
                        <option value={f.code}>{f.name} ({f.code})</option>
                        {
                            f.schools.map(s =>
                                <React.Fragment>
                                    <option value={s.code}>&rarr; {s.name} ({s.code})</option>
                                    {
                                        s.departments.map(d => 
                                            <option value={d.code}>&rarr; &rarr; {d.name} ({d.code})</option>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                )
            }
        </Input>
    </React.Fragment>
}

DepartmentsDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    faculties: PropTypes.object.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired
}

export default React.memo(DepartmentsDropdown);
