import PropTypes from "prop-types";
import React from "react";
import MultiSelect from "../MultiSelect";
import DepartmentsDropdown from "../DepartmentsDropdown";
import { BsFunnel, BsFunnelFill, BsLink } from "react-icons/bs";
import { Button, Popover, PopoverHeader, PopoverBody, Input } from "reactstrap";
import Joi from "joi";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import { withAbort } from "../../../util";
import { FormError } from "..";

/** 
 * @typedef DepartmentModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 */

/** 
 * @typedef SchoolModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 * @property {Array<DepartmentModel>} departments 
 */

/** 
 * @typedef FacultyModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 * @property {Array<SchoolModel>} schools 
 */

const departmentSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional()
});

const schoolSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional(),
    departments: Joi.array().items(departmentSchema).optional()
});

const facultySchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional(),
    schools: Joi.array().items(schoolSchema).allow(null).optional(),
});

/**
 * Validate the faculties array
 *
 * @param {*} faculties
 * @return {Array<FacultyModel>} 
 */
const validateFaculties = faculties => {
    const result = Joi.array().min(1).items(facultySchema).required().validate(faculties);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Faculties Response", result);
    }
    return faculties;
}


/**
 * @typedef Filter
 * @property {String} descriptionContains
 * @property {String} levelEquals
 * @property {Number} yearEquals
 */

/**
 * @typedef Cohort
 * @property {String} id
 * @property {String} department
 * @property {Number} year
 * @property {String} description
 * @property {String} level
 */

const cohortSchema = Joi.object({
    id: Joi.number().required(),
    departments: Joi.array().items(Joi.string()).allow(null).optional(),
    year: Joi.number().required(),
    description: Joi.string().allow(null, "").optional(),
    level: Joi.string().allow(null, "").optional()
})

const Cohorts = props => {
    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    const { id, label, departmentCode, value, disabled, error, onChange, filterDepartments, currentCourseId } = props;

    const [filterOpen, setFilterOpen] = React.useState(false);
    const toggleFilterOpen = () => setFilterOpen(prev => !prev);

    const [descriptionContains, setDescriptionContains] = React.useState("");
    const [levelEquals, setLevelEquals] = React.useState("");
    const [yearEquals, setYearEquals] = React.useState("");
    const [departmentCodeSelected, setDepartmentCodeSelected] = React.useState(departmentCode);
    const [departmentCodeOneOf, setDepartmentCodeOneOf] = React.useState([departmentCode]);

    /** @type {[Array<FacultyModel>, React.Dispatch<Array<FacultyModel>>]} */
    const [faculties, setFaculties] = React.useState([]);

    const [cohortProgrammes, setCohortProgrammes] = React.useState(null);

    const updateDescriptionFilter = function (e) {
        setDescriptionContains(e.target.value);
    }
    const updateLevelFilter = function (e) {
        setLevelEquals(e.target.value);
    }
    const updateYearFilter = function (e) {
        setYearEquals(e.target.value);
    }
    const updateDepartmentCodeFilter = function (e) {
        setDepartmentCodeSelected(e.target.value);
    }

    const getSubUnitsPure = function (code, faculties) {
        if (!code) {
            return [];
        }

        const codes = [code];

        const addAllInSchool = function (school) {
            for (const d of school.departments) {
                codes.push(d.code);
            }
        }

        const addAllInFaculty = function (faculty) {
            for (const s of faculty.schools) {
                codes.push(s.code);
                addAllInSchool(s);
            }
        }

        if (faculties) {
            for (const f of faculties) {
                if (f.code == code) {
                    addAllInFaculty(f);
                    break;
                }
                else {
                    for (const s of f.schools) {
                        if (s.code == code) {
                            addAllInSchool(s);
                            break;
                        }
                    }
                }
            }
        }
        return codes;
    }

    const clearFilters = React.useCallback(() => {
        setDescriptionContains("");
        setLevelEquals("");
        setYearEquals("");
        setDepartmentCodeSelected(departmentCode);
        const codes = getSubUnitsPure(departmentCode, faculties);
        setDepartmentCodeOneOf(codes);
    }, [departmentCode, departmentCodeOneOf]);

    React.useEffect(() => {
        setDepartmentCodeSelected(departmentCode);
    }, [departmentCode]);

    React.useEffect(() => {
        const codes = getSubUnitsPure(departmentCodeSelected, faculties);
        setDepartmentCodeOneOf(codes);
    }, [departmentCodeSelected, faculties]);

    const [wtcohorts, setWtCohorts] = React.useState([]);
    React.useEffect(() => {
        let ignore = false;
        fetchApi('/worktribe-cohorts', { method: "GET" }
        ).then(async function (response) {
            const json = await response.json();
            if (!ignore) {
                setWtCohorts(json);
            }
        });
        return () => {
            ignore = true;
        }
    }, []);

    const [allCohorts, setAllCohorts] = React.useState([]);
    React.useEffect(() => {
        const extra = [];
        const existing = new Set();
        for (const ch of wtcohorts) {
            existing.add(`${ch.id}-${ch.year}`);
        }
        for (const ch of value) {
            if (!existing.has(`${ch.id}-${ch.year}`)) {
                extra.push({ id: ch.id, year: ch.year, level: "XX", departments: [], description: "" });
            }
        }
        setAllCohorts(wtcohorts.concat(extra));
    }, [wtcohorts, value]);

    const filterActive = Boolean(descriptionContains) || Boolean(levelEquals) || Boolean(yearEquals) || Boolean(departmentCodeOneOf);

    const filterFunction = React.useCallback(cohort => {
        if (filterActive) {
            return (!Boolean(descriptionContains) || cohort.description?.toLowerCase().includes(descriptionContains.toLowerCase()))
                && (!Boolean(levelEquals) || cohort.level === levelEquals)
                && (!Boolean(yearEquals) || cohort.year === yearEquals)
                && (!Boolean(departmentCodeOneOf?.length) || cohort.departments?.some(d => departmentCodeOneOf.includes(d)));
        }
        return true;
    }, [descriptionContains, filterActive, levelEquals, yearEquals, departmentCodeOneOf])

    const initializeDepartments = () => withAbort(signal => {
        if (!faculties?.length) {
            const stopWait = waitFor("INIT_DEPARTMENTS_DROPDOWN");
            fetchApi("/faculties", { method: "GET", signal })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                            .then(validateFaculties)
                            .then(facultiesTemp => {
                                setFaculties(facultiesTemp);
                            })
                    } else {
                        throw new FormError("Could not contact Faculties API");
                    }
                })
                .catch(handleError)
                .finally(stopWait)
        }
    });
    React.useEffect(initializeDepartments, [fetchApi, handleError, waitFor]);
    
    const initializeCohortLinks = () => withAbort(signal => {
        if (!cohortProgrammes && currentCourseId) {
            const stopWait = waitFor("INIT_COHORT_COURSES");
            fetchApi("/cohorts/linked", { method: "GET", signal })
                .then(response => {
                    /*
                     * This is messy, and I don't like it, but this is the simplest
                     * fix right now.  There is an issue where DOMExceptions,
                     * that don't appear to actually matter, cut short the 
                     * fetchApi call and cause it to return nothing.
                     * So I will interpret !response as something we can ignore.
                     * This wouldn't be enough, but it only seems to happen
                     * when something will trigger a re-initialise anyway.
                     * 
                     */
                    if (response) {
                        if (response.ok) {
                            return response.json()
                                .then(cohortsTemp => {
                                    const newCohortProgrammes = {};
                                    const courseIdToFilter = `PROG-${currentCourseId}`;
                                    for (const cohort of cohortsTemp) {
                                        newCohortProgrammes[`${cohort.id}-${cohort.year}`] =
                                            cohort.programmeCourseIds.filter(courseId => courseId != courseIdToFilter);
                                    }
                                    setCohortProgrammes(newCohortProgrammes);
                                });
                        } else {
                            throw new FormError("Could not contact cohorts API");
                        }
                    }
                })
                .catch(handleError)
                .finally(stopWait)
        }
    });
    React.useEffect(initializeCohortLinks, [fetchApi, handleError, waitFor, currentCourseId]);



    return <React.Fragment>
        <Popover
            placement="top"
            target="cohort-filter-button"
            isOpen={filterOpen}
        >
            <PopoverHeader>
                Filter cohort options<Button onClick={toggleFilterOpen} close type="button" title="Close cohort filtering" aria-label="Close cohort filtering" />
            </PopoverHeader>
            <PopoverBody className="text-right">
                <Input
                    autoFocus
                    type="text"
                    value={descriptionContains}
                    onChange={updateDescriptionFilter}
                    title="Cohort description search"
                    aria-label="Cohort description search"
                    placeholder="Description search" />
                <br />
                <Input
                    type="select"
                    onChange={updateLevelFilter}
                    value={levelEquals}
                    title="Cohort level filter"
                    aria-label="Cohort level filter" >
                    <option value="">All levels of study</option>
                    <option value="UG">Undergraduate (UG)</option>
                    <option value="PC">Postgraduate Taught (PC)</option>
                    <option value="PR">Postgraduate Research (PR)</option>
                </Input>
                <br />
                <Input
                    type="number"
                    min={1}
                    onChange={updateYearFilter}
                    value={yearEquals}
                    placeholder="Year of study"
                    title="Cohort year of study filter"
                    aria-label="Cohort year of study filter" />
                <br />
                {
                    !Boolean(filterDepartments) && <React.Fragment>
                        <DepartmentsDropdown
                            id="department_filter"
                            faculties={faculties}
                            value={departmentCodeSelected}
                            onChange={updateDepartmentCodeFilter}
                        />
                        <br />
                    </React.Fragment>
                }
                <Button
                    color="danger"
                    onClick={clearFilters}
                    type="button"
                    title="Clear cohort filters"
                    aria-label="Clear cohort filters">Clear</Button>
            </PopoverBody>
        </Popover>
        <MultiSelect
            id={id}
            label={label}
            includedLabel={props.includedLabel}
            options={allCohorts ? filterDepartments ? allCohorts.filter((cohort) => cohort.departments?.includes(departmentCode)) : allCohorts : []}
            names={(cohort) => cohort.description ? `${cohort.description} Part ${cohort.year} (${cohort.id}-${cohort.year})` : `Programme Code: (${cohort.id}-${cohort.year})`} /*  [${cohort.departments?.at(0)}] */
            links={(cohort) => cohortProgrammes ? (cohortProgrammes[`${cohort.id}-${cohort.year}`] ?? []) : []}
            value={value}
            valueComparator={(a, b) => a.id === b.id && a.year === b.year}
            onChange={onChange}
            leftAdornment={<Button
                id="cohort-filter-button"
                type="button"
                onClick={toggleFilterOpen}
                title="filter cohort names"
                aria-label="filter cohort names"
                close
                color="primary"
                className="float-right">
                {filterActive ? <BsFunnelFill /> : <BsFunnel />}
            </Button>}
            filter={filterFunction}
            disabled={disabled}
            error={error}
        />
    </React.Fragment>
}

Cohorts.propTypes = {
    departmentCode: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    filterDepartments: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    includedLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.any).isRequired,
    currentCourseId: PropTypes.string
}

export { cohortSchema };
export default React.memo(Cohorts);
