import { waitFor as libWaitFor } from "@testing-library/react";

/**
 * @callback FunctionWithSignal 
 * @param {AbortSignal} signal
 * @param {...any} args
 * @returns {void}
 */

/**
 * Run a function with an asynchronous abort and return the abort function
 * 
 * To be used as a React.useEffect function which uses a call to fetch()
 * 
 * @param {FunctionWithSignal} func the function to run
 * @returns the abort function
 */
const withAbort = func => {
    const controller = new AbortController();
    func(controller.signal);
    return () => controller.abort();
}

const waitFor = (callback, options={}) => libWaitFor(callback, {...options, timeout: 5000});

export { withAbort, waitFor }