import PropTypes from "prop-types";
import React from "react";
import { Collapse } from "reactstrap";
import InputPrependAppend from "../InputPrependAppend";

const PurposeOther = props => {

    const { show, disabled, error, ...otherProps } = props;

    return <Collapse isOpen={show}>
        <InputPrependAppend
            {...otherProps}
            disabled={!show || disabled}
            type="text"
            error={error}
        />
    </Collapse>
}

PurposeOther.propTypes = {
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string
}

export default React.memo(PurposeOther)