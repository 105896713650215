import PropTypes from "prop-types";
import React from "react";
import * as msal from "@azure/msal-browser";
import urljoin from "url-join";
import Joi from "joi";
import { Spinner } from "reactstrap";
import NoticeArea from "../../NoticeArea";
import { Context as ErrorHandler, HandledError } from "../ErrorHandler";
import { withAbort } from "../../../util";

/**
 * @callback LoginFunction Login to the application
 * @returns {Void}
 */
/**
 * @callback LogoutFunction Logout from the application
 * @returns {Void}
 */
/**
 * @callback FetchApiFunction Fetch data from the secured API
 * @param {String} url the API url to request
 * @param {RequestInit} options fetch options {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_request_options}
 * @returns {Promise<Response>}
 */
/**
 * @typedef {Object} AuthenticationContext
 * @property {User} user the user object
 * @property {LoginFunction} login login to the application
 * @property {LogoutFunction} logout logout from the application
 * @property {FetchApiFunction} fetchApi fetch data from secured API
 */
/** @type {React.Context<AuthenticationContext>} */
const Context = React.createContext();

/**
 * Error occurred during authentication or initialization
 *
 * @class AuthenticationError
 * @extends {HandledError}
 */
class AuthenticationError extends HandledError {
    /**
     * Creates an instance of AuthenticationError.
     * @param {String} message the message
     * @param {Error} [cause=null] the cause
     * @param {*} [detail=null] other information
     * @memberof AuthenticationError
     */
    constructor(message, cause = null, detail = null) {
        super("AuthenticationError", message, cause, detail);
    }
}

/**
 * Error thrown when the user is not logged in and tries to hit the API
 *
 * @class NotLoggedInError
 * @extends {Error}
 */
class NotLoggedInError extends Error {
    /**
     * Creates an instance of NotLoggedInError.
     * @memberof NotLoggedInError
     * @param {Error} cause
     */
    constructor(cause = null) {
        super("user not logged in");
        this.cause = cause;
    }
}

/**
 * @typedef AuthenticatedRole
 * @property {String} ADMIN the admin role
 * @property {String} STAFF the staff role
 */
/**
 * The set of application roles
 * @type {AuthenticatedRole}
 */
const Role = {
    ADMIN: "Administrator",
    STAFF: "Staff",
};

/**
 * @typedef {Object} Configuration
 * @property {Object} msalConfig the authentication client configuration
 * @property {Object} apiConfig the API endpoint configuration
 */
/** Configuration schema @type {Joi.Schema} */
const configSchema = Joi.object({
    msalConfig: Joi.object({}).required(),
    apiConfig: Joi.object({
        urlBase: Joi.string().uri().required(),
        scope: Joi.string().required(),
    }).required(),
});

/**
 * @typedef {Object} User
 * @property {String} username the user name
 * @property {String} displayName the display name
 * @property {String[]} roles the set of roles this user has
 * @property {String} departmentCode The department this user is a member of
 */
/**
 * User schema
 * @type {Joi.Schema}
 */
const userSchema = Joi.object({
    username: Joi.string().required(),
    displayName: Joi.string().required(),
    roles: Joi.array()
        .min(1)
        .unique()
        .items(Joi.string().valid(...Object.values(Role))),
    departmentCode: Joi.string(),
});

/**
 * Request an authentication token, refreshing the authentication if necessary
 *
 * @param {Object} apiConfig the configuration for the API endpoint
 * @param {msal.AccountInfo} account the user account
 * @param {msal.PublicClientApplication} msalObject the authentication client
 * @return {Promise<String>}
 */
const token = (apiConfig, account, msalObject) => {
    const tokenRequest = {
        account,
        scopes: [apiConfig.scope],
        forceRefresh: false,
    };
    try {
        return msalObject
            .acquireTokenSilent(tokenRequest)
            .catch((error) => {
                if (error instanceof msal.InteractionRequiredAuthError) {
                    return msalObject.acquireTokenPopup(tokenRequest);
                } else {
                    throw new AuthenticationError(
                        "Could not authenticate",
                        error,
                        "unable to acquire token"
                    );
                }
            })
            .then((response) => response.accessToken);
    } catch (error) {
        return Promise.reject(error);
    }
};

/**
 * Validate the configuration object against the @type {Joi.Schema}
 *
 * @param {*} config the object to validate
 * @return {Configuration}
 */
const validateConfig = (config) => {
    const result = configSchema.validate(config, { allowUnknown: true });
    if (Boolean(result.error)) {
        throw new AuthenticationError("Could not authenticate", null, result.error);
    }
    return config;
};

/**
 * Validate the user object against the @type {Joi.Schema}
 *
 * @param {*} user the object to validate
 * @return {User}
 */
const validateUser = (user) => {
    const result = userSchema.validate(user, { allowUnknown: true });
    if (Boolean(result.error)) {
        throw new AuthenticationError("Could not authenticate", null, result.error);
    }
    return user;
};

/**
 * Get the current user information from the /currentuser API. Requires authentication
 *
 * @param {Object} apiConfig the configuration for the API endpoint
 * @param {msal.AccountInfo} account the user account
 * @param {msal.PublicClientApplication} msalObject the authentication client
 * @returns {Promise<User>}
 */
const fetchCurrentUser = (apiConfig, account, msalObject, signal) =>
    token(apiConfig, account, msalObject)
        .then((token) =>
            fetch(urljoin(apiConfig.urlBase, "/currentuser"), {
                method: "GET",
                headers: { Authorization: [`Bearer ${token}`] },
                signal,
            })
        )
        .then((response) => response.json())
        .then(validateUser);

/**
 * Provider for authentication context
 *
 * @param {Object} props
 * @return {*}
 */
const Provider = (props) => {
    const [apiConfig, setApiConfig] = React.useState(null);

    /** @type {[msal.PublicClientApplication, React.Dispatch<msal.PublicClientApplication>]} */
    const [msalObject, setMsalObject] = React.useState(null);

    /** @type {[msal.AccountInfo, React.Dispatch<msal.AccountInfo>]} */
    const [account, setAccount] = React.useState(null);

    /** @type {[User, React.Dispatch<user>]} */
    const [user, setUser] = React.useState(null);
    const [ready, setReady] = React.useState(false);

    const { handleError } = React.useContext(ErrorHandler);

    /** @type {LoginFunction} */
    const login = React.useCallback(() => {
        try {
            return msalObject
                .loginPopup({
                    scopes: [apiConfig.scope],
                    loginHint: "",
                    prompt: "select_account",
                })
                .then((response) => {
                    msalObject.setActiveAccount(response.account);
                    return fetchCurrentUser(apiConfig, response.account, msalObject).then(
                        (userTemp) => {
                            setUser(userTemp);
                            setAccount(response.account);
                        }
                    );
                })
                .catch((error) => {
                    if (error?.errorCode === "popup_window_error") {
                        throw new AuthenticationError(
                            "Your browser blocked an authentication popup - please press the Login button",
                            error,
                            "unable to login and update account"
                        );
                    } else {
                        throw new AuthenticationError(
                            `Could not authenticate. Refreshing the page may fix the error. The error was "${error?.errorCode}".`,
                            error,
                            "unable to login and update account"
                        );
                    }
                });
        } catch (error) {
            setAccount(null);
            setUser(null);
            return Promise.reject(error);
        }
    }, [apiConfig, msalObject]);

    /** @type {LogoutFunction} */
    const logout = React.useCallback(() => {
        try {
            return msalObject
                .logoutPopup()
                .then(() => {
                    setAccount(null);
                    setUser(null);
                })
                .catch((error) => {
                    throw new AuthenticationError(
                        "Could not authenticate",
                        error,
                        "unable to logout"
                    );
                });
        } catch (error) {
            setAccount(null);
            setUser(null);
            return Promise.reject(error);
        }
    }, [msalObject]);

    /** @type {FetchApiFunction} */
    const fetchApi = React.useCallback(
        (url, options = {}) => {
            if (account !== null) {

                const fullUrl = urljoin(apiConfig.urlBase, url);
                if (!Boolean(options.headers)) {
                    options.headers = new Headers();
                }

                return token(apiConfig, account, msalObject)
                    .then((token) => {
                        options.headers.append("Authorization", `Bearer ${token}`);
                        return fetch(fullUrl, options);
                    })
                    .catch((error) => {
                        // Attempt to suppress an error when we get
                        // DOMException: The user aborted a request
                        // which then effectively logs the user out.
                        if (!(error instanceof DOMException)) {
                            setAccount(null);
                            setUser(null);
                            throw new NotLoggedInError(error);
                        }
                    });
            }
            return Promise.reject(new NotLoggedInError());
        },
        [apiConfig, msalObject, account]
    );

    /**
     * Initialize the authentication context
     *
     * Download configuration file
     * Build Authentication Client
     * Attempt login from pre-existing credentials is found
     * @return {Void}
     */
    const initialize = () =>
        withAbort((signal) => {
            fetch("/config.json", { method: "GET", signal })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new AuthenticationError(
                        "Could not authenticate",
                        null,
                        "unable to get configuration"
                    );
                })
                .then(validateConfig)
                .then((config) => {
                    if (!Boolean(config.msalConfig)) {
                        throw new AuthenticationError(
                            "Could not authenticate",
                            null,
                            "unable to build configuration"
                        );
                    }

                    let msalObjectTemp;
                    try {
                        msalObjectTemp = new msal.PublicClientApplication(
                            config.msalConfig
                        );
                    } catch (error) {
                        throw new AuthenticationError(
                            "Could not authenticate",
                            error,
                            "unable to build configuration"
                        );
                    }

                    let accountTemp;
                    try {
                        accountTemp = msalObjectTemp.getActiveAccount() || null;
                    } catch (error) {
                        throw new AuthenticationError(
                            "Could not authenticate",
                            error,
                            "unable to read account"
                        );
                    }

                    if (Boolean(accountTemp)) {
                        return fetchCurrentUser(
                            config.apiConfig,
                            accountTemp,
                            msalObjectTemp,
                            signal
                        )
                            .then((userTemp) => {
                                setUser(userTemp);
                                setAccount(accountTemp);
                                setApiConfig(config.apiConfig);
                                setMsalObject(msalObjectTemp);
                                setReady(true);
                            })
                            .catch((error) => {
                                setUser(null);
                                setAccount(null);
                                setReady(true);
                                throw new AuthenticationError(
                                    "Could not authenticate",
                                    error,
                                    "could not fetch current user information"
                                );
                            });
                    } else {
                        setApiConfig(config.apiConfig);
                        setMsalObject(msalObjectTemp);
                        setReady(true);
                    }
                })
                .catch(handleError);
        });
    React.useEffect(initialize, [handleError]);

    return (
        <Context.Provider value={{ login, logout, fetchApi, user }}>
            {ready ? (
                props.children
            ) : (
                <React.Fragment>
                    <NoticeArea />
                    <div className="d-flex vh-100 justify-content-center align-items-center">
                        <Spinner />
                    </div>
                </React.Fragment>
            )}
        </Context.Provider>
    );
};

Provider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

export { Context, Role, NotLoggedInError };
export default React.memo(Provider);
