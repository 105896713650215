import { Badge } from "reactstrap";
import React from "react";
const PendingTag = props =>
{
    if(props.value==0)
    {
        return <Badge color="info" style={{ fontSize: "11px" }}> Pending approval</Badge>
    }
    
}

export default React.memo(PendingTag);