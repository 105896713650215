import React from "react";
import NoticeArea from "../../../NoticeArea";
import SandboxButtons from "../../../FormItems/SandboxButtons"


const UltraSandbox = () => {
    return <React.Fragment>
        <h2>Blackboard Ultra sandbox courses</h2>
        <NoticeArea />
        <p>All members of staff can create a sandbox course with which to experiment with Blackboard features.</p>
        <ul>
            <li>You can only have one such course.</li>
            <li>Sandbox courses can <strong>not</strong> be used for teaching (any undergraduate or postgraduate taught students enrolled on it will be removed).</li>
            <li>Your sandbox will remain open while you remain at the University unless you explicitly remove it.</li>
        </ul>

        <div className="alert alert-info">
            <p>This page will create a Blackboard Ultra course.  
                If you want to create a Blackboard Original sandbox then <a href="/sandbox">you can additionally create one here</a>.
                You may have one Original sandbox and one Ultra sandbox.
            </p>
        </div>

        <h3>Your Ultra sandbox course</h3>
        <SandboxButtons apiEndpoint="/ultrasandbox" sandboxDescription="sandbox"/>
    </React.Fragment >
}

export default React.memo(UltraSandbox);