import PropTypes from "prop-types";
import Joi from "joi";
import React from "react"
import { FormError } from "..";
import { Authentication, ErrorHandler, Waiting } from "../../Store";
import InputPrependAppend from "../InputPrependAppend";
import { withAbort } from "../../../util";

/** 
 * @typedef SemesterModel
 * @property {String} id
 * @property {String} name
 */

const semesterSchema = Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required()
});

/**
 * Validate the semesters array
 *
 * @param {*} semesters
 * @return {Array<SemesterModel>} 
 */
const validateSemesters = semesters => {
    const result = Joi.array().items(semesterSchema).validate(semesters);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Semesters Response", result);
    }
    return semesters;
}

const Semesters = props => {

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    /** @type {[Array<SemesterModel>, React.Dispatch<Array<SemesterModel>>]} */
    const [semesters, setSemesters] = React.useState(null);

    const { url, onChange, onReady } = props;
    const initialize = () => withAbort(signal => {
        const stopWait = waitFor("INIT_SEMESTERS");
        fetchApi(url, { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateSemesters)
                        .then(semestersTemp => {
                            setSemesters(semestersTemp);
                            onChange(semestersTemp[0]);
                        })
                } else {
                    throw new FormError("Could not contact Semesters API");
                }
            })
            .then(() => onReady && onReady())
            .catch(handleError)
            .finally(stopWait)
    });
    React.useEffect(initialize, [fetchApi, handleError, onChange, onReady, url, waitFor]);

    const handleOnChange = React.useCallback(value =>
        onChange(semesters.find(semester => semester.id === value) || null),
        [semesters, onChange]
    );

    return <InputPrependAppend type="select" label={props.label} id={props.id} value={props.value?.id || ""} onChange={handleOnChange} disabled={props.disabled} error={props.error}>
        {semesters?.map(semester =>
            <option key={semester.id} value={semester.id}>{semester.name}</option>
        )}
        <option value="">{props.noValue}</option>
    </InputPrependAppend>
}

Semesters.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.object,
    noValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    onReady: PropTypes.func
}

export { semesterSchema };
export default React.memo(Semesters);
