import PropTypes from "prop-types"
import React from "react";
import { Table } from "reactstrap";
import { withAbort } from "../../util";
import { Authentication, HandledError, ErrorHandler, Waiting } from "../Store";
import PagingControls from "./PagingControls";

const PaginationColumn = React.memo(() => null);

PaginationColumn.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(
            PropTypes.node
        )
    ]).isRequired,
    cell: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.string
    ])
}

const createBlankPagingLinks = () => {
    return {
        first: null,
        prev: null,
        next: null,
        last: null,
        totalCount: null,
        startCount: null,
        currentCount: null
    };
}

const getIntHeader = (response, key) => {
    const headerValue = response.headers.get(key);
    if (headerValue === null) return null;
    return parseInt(headerValue);
}

const extractPagingLinks = (response, rows) => {
    const pagingLinks = createBlankPagingLinks();
    const linkHeader = response.headers.get('link');
    if (linkHeader != null) {
        linkHeader.split(",").forEach(element => {
            const match = /<(.+)>;\s*rel="([a-z]+)"/.exec(element);
            pagingLinks[match[2]] = match[1];
        });
    }

    pagingLinks.totalCount = getIntHeader(response, 'x-total-count');
    pagingLinks.startCount = getIntHeader(response, 'x-start-count');
    pagingLinks.currentCount = rows.length;

    return pagingLinks;
}

const responseBodyValidatorDefault = data => data;

const emptyMessageDefault = "No Data";

const PaginationTable = props => {
    const { url, children, rowKey, responseBodyValidator = responseBodyValidatorDefault, emptyMessage = emptyMessageDefault } = props;
    const columns = Array.isArray(children) ? children : [children];

    const [rows, setRows] = React.useState(null);
    const [currentUrl, setCurrentUrl] = React.useState(url);
    const [pagingLinks, setPagingLinks] = React.useState(createBlankPagingLinks());


    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    const initialize = () => withAbort(signal => {
        const stopWait = waitFor("LOAD_TABLE");
        fetchApi(currentUrl, { method: "GET" })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(responseBodyValidator)
                        .then(rowsTemp => {
                            !signal.aborted && setPagingLinks(extractPagingLinks(response, rowsTemp));
                            !signal.aborted && setRows(rowsTemp);
                        })
                } else {
                    throw new HandledError("Could not build table from response data");
                }
            })
            .catch(handleError)
            .finally(stopWait)
    });
    React.useEffect(initialize, [currentUrl, fetchApi, handleError, responseBodyValidator, waitFor]);
    React.useEffect(() => setCurrentUrl(url), [url]);

    return <React.Fragment>
        <Table>
            <thead>
                <tr>{columns.map((column, index) => <th key={index}>{column.props.children}</th>)}</tr>
            </thead>
            <tbody>
                {rows?.map(row => <tr key={rowKey(row)}>
                    {columns.map((column, index) => <td key={index}>{typeof (column.props.cell) === "function" ? column.props.cell(row) : column.props.cell}</td>)}
                </tr>)}
            </tbody>
        </Table>
        {!Boolean(rows?.length) && <div className="w-100 text-center"><strong>{emptyMessage}</strong></div>}
        <PagingControls pagingLinks={pagingLinks} onSetUrl={setCurrentUrl} />
    </React.Fragment>
}

PaginationTable.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(
            PropTypes.node
        )
    ]).isRequired,
    url: PropTypes.string.isRequired,
    rowKey: PropTypes.func.isRequired,
    responseBodyValidator: PropTypes.func,
    emptyMessage: PropTypes.string
}

export { PaginationColumn }
export default React.memo(PaginationTable);
