import Joi from "joi";
import PropTypes from "prop-types";
import React from "react";
import { Notice } from "../../../../Store";
import { HandledError } from "../../../../Store";
import NoticeArea from "../../../../NoticeArea";
import { useLocation } from "react-router";
import PaginationTable, { PaginationColumn } from "../../../../PaginationTable";
import capitalize from "capitalize";
import { Link } from "react-router-dom";
/**
 * @typedef CourseRequestModel
 * @property {Number} id
 * @property {String} courseId
 * @property {String} requestDate
 * @property {String} requestedBy
 */
const courseRequestSchema = Joi.object({
  id: Joi.number().required(),
  requestDate: Joi.date().required(),
  requestedBy: Joi.string().required(),
  courseType: Joi.string().valid("ARBITRARY", "PROGRAMME").required(),
}).unknown(true);

const buildLink = (path, id, courseType) => {
  switch (courseType) {
    case "ARBITRARY":
      return `${path}/arbitrary-course/${id}`;
    case "PROGRAMME":
      return `${path}/programme-course/${id}`;
    default:
      return null;
  }
};

/**
 * Validate the array
 *
 * @param {*} requests
 * @return {Array<CourseRequestModel>}
 */
const validateCourseRequests = (requests) => {
  const result = Joi.array().items(courseRequestSchema).validate(requests);
  if (Boolean(result.error)) {
    throw new HandledError("Malformed Course Requests Response", result);
  }
  return requests;
};

const CourseRequestList = (props) => {
  const { info, danger } = React.useContext(Notice);
  const location = useLocation();

  const alertFromLocationState = () => {
    setTimeout(() => {
      switch (location?.state?.action) {
        case "ERROR":
          danger(location.state.data);
          break;
        case "APPROVE":
          info(
            <React.Fragment>
              Thank you for using this service.{" "}
              <a
                href={location.state.data.link}
                target="_blank"
                rel="noreferrer"
              >
                {location.state.data.courseId}
              </a>{" "}
              has been approved.
            </React.Fragment>
          );
          break;
        case "REJECT":
          info(
            <React.Fragment>
              Thank you for using this service.{" "}
              <strong>{location.state.data.courseId}</strong> has been rejected.
            </React.Fragment>
          );
          break;
        default:
          break;
      }
    }, 200);
  };
  React.useEffect(alertFromLocationState, [danger, info, location]);

  const { path } = props;

  return (
    <React.Fragment>
      <h1>Pending Course requests</h1>
      <NoticeArea />
      <PaginationTable
        url={"/requests?state=Created"}
        rowKey={(request) => `${request.courseType}-${request.id}`}
        responseBodyValidator={validateCourseRequests}
        emptyMessage="No pending requests"
      >
        <PaginationColumn
          cell={(request) =>
            new Date(request.requestDate).toLocaleString("en-GB")
          }
        >
          Request date
        </PaginationColumn>
        <PaginationColumn cell={(request) => request.requestedBy}>
          Requester
        </PaginationColumn>
        <PaginationColumn cell={(request) => request.assignedTo}>
          Assigned to
        </PaginationColumn>
        <PaginationColumn cell={(request) => request.courseId}>
          Course ID
        </PaginationColumn>
        <PaginationColumn cell={(request) => capitalize(request.courseType)}>
          Type
        </PaginationColumn>
        <PaginationColumn
          cell={(request) => (
            <Link
              aria-label={"edit approve"}
              to={buildLink(path, request.id, request.courseType)}
            >
              edit/approve
            </Link>
          )}
        >
          &nbsp;
        </PaginationColumn>
      </PaginationTable>
    </React.Fragment>
  );
};

CourseRequestList.propTypes = {
  path: PropTypes.string.isRequired,
};

export default React.memo(CourseRequestList);
