import React from "react";
import { useHistory, useLocation } from "react-router";
import { Authentication, ErrorHandler, Role } from "../../../Store";
import NoticeArea from "../../../NoticeArea";
import { Button } from "reactstrap";

const LoginPage = () => {
  const { login, user } = React.useContext(Authentication);

  const history = useHistory();
  const location = useLocation();

  const referer = location.state?.referer;

  const { handleError } = React.useContext(ErrorHandler);

  const safeLogin = React.useCallback(
    () => login().catch(handleError),
    [handleError, login]
  );

  const redirect = () => {
    if (Boolean(referer) && Boolean(user)) {
      history.push(referer);
    } else if (user?.roles.includes(Role.ADMIN)) {
      history.push("/approvals");
    } else if (user?.roles.includes(Role.STAFF)) {
      history.push("/home");
    }
  };
  React.useEffect(redirect, [history, user, referer, login, handleError]);

  return (
    <React.Fragment>
      <NoticeArea />
      <p data-testid="LoginPage">Please Login to your University Account.</p>
      <Button color="primary" onClick={safeLogin}>
        Login
      </Button>
    </React.Fragment>
  );
};

export default React.memo(LoginPage);
