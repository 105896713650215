import { HandledError } from "../Store";

class FormError extends HandledError {
    constructor(message, validation = null) {
        super("FormError", message, null, validation);
    }
}

export { FormError }
export { default as Departments } from "./Departments";
export { default as DepartmentsDropdown } from "./DepartmentsDropdown";
export { default as DepartmentTemplates } from "./DepartmentTemplates";
export { default as Discuss } from "./Discuss";
export { default as InputPrependAppend } from "./InputPrependAppend";
export { default as PurposeOther } from "./PurposeOther";
export { default as Purposes } from "./Purposes";
export { default as Semesters } from "./Semesters";
export { default as Terms } from "./Terms";
export { default as AcademicYears } from "./AcademicYears";
export { default as UserData } from "./UserData";
export { default as IsCopyRequest } from "./IsCopyRequest";
export { default as CourseToCopy } from "./CourseToCopy";
export { default as RequestOverview } from "./RequestOverview";
export { default as AssignedTo } from "./AssignedTo";
export { default as HelpIcon } from "./HelpIcon";
export { default as Cohorts } from "./Cohorts";
export { default as MultiSelect } from "./MultiSelect";
export { default as IsUltraCourse } from "./IsUltraCourse";
export { default as UltraCourseTemplates } from "./UltraCourseTemplates";
