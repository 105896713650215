import PropTypes from "prop-types";
import React from "react";
import {
    AssignedTo,
    Departments,
    DepartmentTemplates,
    Discuss,
    InputPrependAppend,
    PurposeOther,
    Purposes,
    RequestOverview,
    Semesters,
    AcademicYears,
    Terms,
    UserData,
    IsUltraCourse,
    UltraCourseTemplates
} from "../../../../FormItems";
import { Link, useParams } from 'react-router-dom';
import NoticeArea from "../../../../NoticeArea";
import { Alert, Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { Authentication, ErrorHandler, Notice, Waiting } from "../../../../Store";
import { HandledError } from "../../../../Store";
import Joi from "joi";
import { withAbort } from "../../../../../util";
import capitalize from "capitalize";

/** 
 * @typedef CoursesModel
 * @property {Number} id
 * @property {String} courseId
 * @property {Boolean} isUltraCourse
 * @property {String} ultraCourseTemplate
 * @property {String} type New or Copy
 * @property {String} courseName
 * @property {String} departmentCode
 * @property {String} departmentTemplateCode
 * @property {String} academicYearCode
 * @property {String} termCode
 * @property {String} semesterCode
 * @property {String} courseUse
 * @property {String} courseUseOtherDetails
 * @property {String} containsUserData
 * @property {String} wouldLikeToDiscuss
 * @property {String} additionalNotes
 * @property {String} copyOfCourseId
 * @property {String} copyOfCourseName
 * @property {String} requestDate
 * @property {String} requestedBy
 * @property {String} assignedTo
 * @property {String} state
 */

/** 
 * @typedef FormDataModel
 * @property {Boolean} isUltraCourse
 * @property {String} ultraCourseTemplate
 * @property {String} type New or Copy
 * @property {import("../../../FormItems/AcademicYears").AcademicYearModel} academicYear
 * @property {import("../../../FormItems/Terms").TermModel} term
 * @property {String} name
 * @property {String} shortName
 * @property {import("../../../FormItems/Departments").DepartmentModel} department
 * @property {import("../../../FormItems/DepartmentTemplates").DepartmentTemplateModel} departmentTemplate
 * @property {import("../../../FormItems/Semesters").SemesterModel} semester
 * @property {import("../../../FormItems/Purposes").PurposeModel} purpose
 * @property {String} purposeOther
 * @property {Boolean} userData
 * @property {Boolean} discuss
 * @property {String} comments
 * @property {String} copyOfCourseId
 * @property {String} copyOfCourseName
 * @property {String} requestedBy
 * @property {String} requestDate
 * @property {String} assignedTo
 * @property {String} rejectReason
 */

/** @type {FormDataModel} */
const initialFormData = {
    isUltraCourse: null,
    ultraCourseTemplate: null,
    type: "New",
    academicYear: null,
    term: null,
    name: null,
    shortName: null,
    department: null,
    departmentTemplate: null,
    semester: null,
    purpose: null,
    purposeOther: null,
    userData: null,
    discuss: null,
    comments: null,
    copyOfCourseId: null,
    copyOfCourseName: null,
    requestedBy: null,
    requestDate: null,
    assignedTo: null,
    rejectReason: null
}

const formDataSchema = Joi.object({
    isUltraCourse: Joi.boolean().required(),
    ultraCourseTemplate: Joi.string().allow(null).optional(),
    type: Joi.string().valid("New", "Copy").required(),
    academicYear: Joi.object({
        code: Joi.string().required(),
        name: Joi.string().allow(null).optional(),
        isDefault: Joi.boolean().allow(null).optional()
    }).allow(null).optional(),
    term: Joi.object({
        id: Joi.string().required(),
        name: Joi.string().allow(null).optional(),
        shortName: Joi.string().allow(null).optional(),
        isDefault: Joi.boolean().allow(null).optional()
    }).allow(null).optional(),
    name: Joi.string().required(),
    shortName: Joi.string().pattern(/^[A-Z0-9-]+/).required(),
    department: Joi.object({
        code: Joi.string().required(),
        name: Joi.string().allow(null).optional(),
        templateCode: Joi.string().allow(null).optional()
    }).required(),
    departmentTemplate: Joi.object({
        code: Joi.ref("...department.templateCode"),
        name: Joi.string().allow(null).optional()
    }).allow(null).optional(),
    semester: Joi.object({
        id: Joi.string().required(),
        name: Joi.string().allow(null).optional()
    }).allow(null).optional(),
    purpose: Joi.object({
        id: Joi.string().required(),
        name: Joi.string().allow(null).optional(),
        furtherDetailRequired: Joi.boolean().allow(null).optional()
    }).required(),
    purposeOther: Joi.when('purpose.furtherDetailRequired', {
        is: true,
        then: Joi.string().required(),
        otherwise: Joi.allow(null).optional()
    }),
    userData: Joi.boolean().required(),
    discuss: Joi.boolean().allow(null).optional(),
    comments: Joi.string().allow(null, "").optional(),
    copyOfCourseId: Joi.string().allow(null, "").optional(),
    copyOfCourseName: Joi.string().allow(null, "").optional(),
    requestedBy: Joi.string().allow(null, "").optional(),
    requestDate: Joi.date().allow(null, "").optional(),
    rejectReason: Joi.string().allow(null, "").optional(),
    assignedTo: Joi.string().allow(null, "").optional()
});

/**
 * Validate the formData
 *
 * @param {*} templates
 * @return {Joi.ValidationResult} 
 */
const validateFormData = formData => formDataSchema.validate(formData, { abortEarly: false });

class ApprovalError extends HandledError {
    constructor(message) {
        super("ApprovalError", message);
    }
}

/**
 * @param {Joi.ValidationResult} validation
 * @param {(String|Number)[]} path
 * @returns {Boolean}
 */
const hasError = (validation, path) => Boolean(validation?.error?.details?.find(detail => path.every((segment, index) => segment === detail?.path[index])));

const Approval = props => {

    const mounted = React.useRef();

    const { requestId } = useParams();

    const { handleError } = React.useContext(ErrorHandler);
    const { fetchApi } = React.useContext(Authentication);
    const { waitFor, waiting } = React.useContext(Waiting);
    const { info, danger } = React.useContext(Notice);

    /** @type {[FormDataModel, React.Dispatch<FormDataModel>]} */
    const [formData, setFormData] = React.useState(initialFormData);

    const [formReady, setFormReady] = React.useState(false);
    const [formItemsReady, setFormItemsReady] = React.useState({
        term: false,
        department: false,
        departmentTemplate: false,
        semester: false,
        purpose: false
    });
    const onTermReady = React.useCallback(() => Boolean(mounted.current) && setFormItemsReady(prev => ({ ...prev, term: true })), []);
    const onDepartmentReady = React.useCallback(() => Boolean(mounted.current) && setFormItemsReady(prev => ({ ...prev, department: true })), []);
    const onDepartmentTemplateReady = React.useCallback(() => Boolean(mounted.current) && setFormItemsReady(prev => ({ ...prev, departmentTemplate: true })), []);
    const onSemesterReady = React.useCallback(() => Boolean(mounted.current) && setFormItemsReady(prev => ({ ...prev, semester: true })), []);
    const onPurposeReady = React.useCallback(() => Boolean(mounted.current) && setFormItemsReady(prev => ({ ...prev, purpose: true })), []);

    const [editMode, setEditMode] = React.useState(false);
    const enableEdit = () => setEditMode(true);

    const onIsUltraCourse = React.useCallback(isUltraCourse => setFormData(prev => ({...prev, isUltraCourse})), []);
    const onUltraCourseTemplate = React.useCallback(ultraCourseTemplate => setFormData(prev => ({ ...prev, ultraCourseTemplate: ultraCourseTemplate?.value || null })), []);
    const onAcademicYearChange = React.useCallback(academicYear => setFormData(prev => ({...prev, academicYear})), []);
    const onTermChange = React.useCallback(term => setFormData(prev => ({ ...prev, term })), []);
    const onNameChange = React.useCallback(name => setFormData(prev => ({ ...prev, name })), []);
    const onShortNameChange = React.useCallback(shortName => setFormData(prev => ({ ...prev, shortName: shortName?.toUpperCase().replace(/\s+/g, "-").replace(/[^A-Z0-9-]/g, '') })), []);
    const onDepartmentChange = React.useCallback(department => setFormData(prev => ({ ...prev, department, departmentTemplate: null })), []);
    const onDepartmentTemplateChange = React.useCallback(departmentTemplate => setFormData(prev => ({ ...prev, departmentTemplate })), []);
    const onSemesterChange = React.useCallback(semester => setFormData(prev => ({ ...prev, semester })), []);
    const onPurposeChange = React.useCallback(purpose => setFormData(prev => ({ ...prev, purpose, purposeOther: null })), []);
    const onPurposeOtherChange = React.useCallback(purposeOther => setFormData(prev => ({ ...prev, purposeOther })), []);
    const onUserDataChange = React.useCallback(userData => setFormData(prev => ({ ...prev, userData })), []);
    const onDiscussChange = React.useCallback(discuss => setFormData(prev => ({ ...prev, discuss })), []);
    const onCommentsChange = React.useCallback(comments => setFormData(prev => ({ ...prev, comments })), []);
    const onAssignedToChange = React.useCallback(assignedTo => setFormData(prev => ({ ...prev, assignedTo })), []);
    const onRejectReasonChange = React.useCallback(rejectReason => setFormData(prev => ({ ...prev, rejectReason })), []);

    const [submitted, setSubmitted] = React.useState(false);
    const [courseLink, setCourseLink] = React.useState(false);

    /** @type {[Joi.ValidationResult, React.Dispatch<Joi.ValidationResult>]} */
    const [validation, setValidation] = React.useState(null);

    const applyFormValues = React.useCallback(course => {

        if (course.state !== 0) {
            throw new ApprovalError(`This course has already been ${course.state === 1 ? "rejected" : "approved"}. Please go back to the list.`);
        }
        setFormData(prev => ({
            ...prev,
            isUltraCourse: course?.isUltraCourse,
            ultraCourseTemplate: course?.ultraCourseTemplate,
            type: course?.type,
            academicYear: Boolean(course?.academicYearCode) ? {
                code: course?.academicYearCode
            } : null,
            term: Boolean(course?.termCode) ? {
                id: course?.termCode
            } : null,
            name: course?.courseName,
            shortName: course?.courseId,
            department: {
                code: course?.departmentCode,
                templateCode: course?.departmentTemplateCode || null
            },
            departmentTemplate: {
                code: course?.departmentTemplateCode
            },
            semester: Boolean(course?.semesterCode) ? {
                id: course?.semesterCode
            } : null,
            purpose: Boolean(course?.courseUse) ? {
                id: course?.courseUse,
                furtherDetailRequired: Boolean(course?.courseUseOtherDetails),
            } : null,
            purposeOther: course?.courseUseOtherDetails,
            userData: course?.containsUserData,
            discuss: course?.wouldLikeToDiscuss,
            comments: course?.additionalNotes,
            copyOfCourseId: course?.copyOfCourseId,
            copyOfCourseName: course?.copyOfCourseName,
            requestedBy: course?.requestedBy,
            requestDate: course?.requestDate,
            assignedTo: course?.assignedTo
        }))
    }, []);

    const initialize = React.useCallback(() => withAbort(signal => {
        setFormReady(false);
        if (Object.values(formItemsReady).some(ready => !ready)) {
            return;
        }
        const stopWait = waitFor("AUTOFILL_FORM");
        fetchApi(`/requests/${requestId}`, { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    throw new ApprovalError("This request does not exist. Please return to the approval list page.")
                } else {
                    throw new ApprovalError("Could not contact Requests API");
                }
            })
            .then(applyFormValues)
            .then(() => setFormReady(true))
            .catch(handleError)
            .finally(stopWait);
    }), [applyFormValues, fetchApi, formItemsReady, handleError, requestId, waitFor]);
    React.useEffect(initialize, [initialize]);

    const cancelEdit = React.useCallback(() => {
        setEditMode(false);
        initialize();
    }, [initialize])

    /** @type {[String, React.Dispatch<String>]} */
    const [confirmation, setConfirmation] = React.useState(null);
    const confirmApprove = () => setConfirmation("APPROVE");
    const confirmReject = () => {
        setFormData(prev => ({ ...prev, rejectReason: null }));
        setConfirmation("REJECT");
    }
    const closeConfirmation = () => {
        setFormData(prev => ({ ...prev, rejectReason: null }));
        setConfirmation(null);
    };

    const handleSubmit = React.useCallback(e => {
        e.preventDefault();
        if ("APPROVE" === confirmation) {
            const stopWait = waitFor("REQUEST_APPROVE");
            const result = validateFormData(formData);
            setValidation(result);

            if (Boolean(result.error)) {
                stopWait();
                closeConfirmation();
                danger("Please check you have provided all the information required and then select Approve or Reject.")
            } else {
                const postData = {
                    id: parseInt(requestId),
                    isUltraCourse: formData.isUltraCourse,
                    ultraCourseTemplate: formData.ultraCourseTemplate,
                    type: formData.type,
                    courseId: formData.shortName,
                    courseName: formData.name,
                    departmentCode: formData.department.code,
                    departmentTemplateCode: formData.departmentTemplate?.code || null,
                    academicYearCode: formData.academicYear?.code || null,
                    termCode: formData.term?.id || null,
                    semesterCode: formData.semester?.id || null,
                    courseUse: formData.purpose.id,
                    courseUseOtherDetails: formData.purposeOther || null,
                    containsUserData: formData.userData || false,
                    wouldLikeToDiscuss: formData.discuss || false,
                    additionalNotes: formData.comments || null,
                    copyOfCourseId: formData.copyOfCourseId || null,
                    copyOfCourseName: formData.copyOfCourseName || null
                }

                const headers = new Headers();
                headers.append("Content-Type", "application/json")
                fetchApi(`/requests/${requestId}/approve`, { method: "POST", body: JSON.stringify(postData), headers })
                    .then(response => {
                        if (response.ok) {
                            response.json()
                                .then(newCourse => {
                                    setSubmitted(true);
                                    setCourseLink(newCourse.link);
                                    info("This course has now been successfully approved. Please return to the approval list page.");
                                });
                        } else {
                            throw new ApprovalError("Could not contact Request approval API");
                        }
                    })
                    .catch(handleError)
                    .finally(closeConfirmation)
                    .finally(stopWait)
            }
        } else if ("REJECT" === confirmation) {
            const stopWait = waitFor("REQUEST_REJECT");

            const headers = new Headers();
            headers.append("Content-Type", "application/json")
            fetchApi(`/requests/${requestId}/reject`, { method: "POST", body: JSON.stringify({ reason: formData?.rejectReason }), headers })
                .then(response => {
                    if (response.ok) {
                        setSubmitted(true);
                        info("This course has now been rejected. Please return to the approval list page.");
                    } else {
                        throw new ApprovalError("Could not contact Request rejection API");
                    }
                })
                .catch(handleError)
                .finally(closeConfirmation)
                .finally(stopWait)
        }
    }, [confirmation, danger, fetchApi, formData, handleError, info, requestId, waitFor]);

    const formEditable = Boolean(!waiting && editMode && formReady);
    const formSubmittable = Boolean(!waiting && formReady && !submitted);

    return <Form ref={mounted}>
        <Modal isOpen={Boolean(confirmation)} toggle={!waiting && closeConfirmation}>
            <ModalHeader>{confirmation && capitalize(confirmation)} confirmation</ModalHeader>
            <ModalBody>
                <p>Are you sure you want to {confirmation?.toLowerCase()} this course?</p>
                <Alert color="warning">
                    This cannot be undone!
                </Alert>
                {"APPROVE" === confirmation && <Alert color="info">
                    Course approvals could take several minutes to process. Please be patient and do not close this page.
                </Alert>}
                {"REJECT" === confirmation && <InputPrependAppend
                    id="rejectReason"
                    label={<React.Fragment><span className="text-danger">*</span><span>Please provide a reason for this rejection.</span></React.Fragment>}
                    type="textarea"
                    placeholder="required"
                    value={formData.rejectReason}
                    onChange={onRejectReasonChange}
                />}
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" disabled={waiting} onClick={closeConfirmation}>Cancel</Button>
                <Button type="submit" color="danger" onClick={handleSubmit} disabled={(confirmation === "REJECT" && !Boolean(formData.rejectReason)) || waiting || !formSubmittable}>
                    {waiting && <React.Fragment><Spinner
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />{" "}</React.Fragment>}
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
        <p><Link to={props.back}>&lt; back to list</Link></p>
        <NoticeArea />
        {Boolean(courseLink) && <p><a class="btn btn-success" target="_blank" rel="noreferrer" href={courseLink}>Go to new Blackboard course</a></p>}
        {formSubmittable && <AssignedTo
            assignedTo={formData.assignedTo}
            onChange={onAssignedToChange}
            postUrl={`/requests/${requestId}/assignself`}
        />}
        {formReady && <RequestOverview
            type={formData.type}
            requestedBy={formData.requestedBy}
            requestDate={formData.requestDate}
            copyOfCourseId={formData.copyOfCourseId}
            copyOfCourseName={formData.copyOfCourseName}
        />}
        <IsUltraCourse
            id="isUltraCourse"
            label="Do you want to create an ultra or original course?"
            value={formData.isUltraCourse}
            onChange={onIsUltraCourse}
            disabled={!formEditable}
            error={hasError(validation, ["isUltraCourse"]) ? "Please choose whether this is an Ultra or Original course" : null}
        />
        {formData.isUltraCourse && <UltraCourseTemplates
            url="templates/ultra-courses"
            id="ultraCourseTemplates"
            label="Course Template"
            noValue="No course template"
            value={formData.ultraCourseTemplate}
            onChange={onUltraCourseTemplate}
            disabled={!formEditable}
        />}
        <AcademicYears
            id="academicYear"
            label="Academic year"
            noValue="No academic year"
            value={formData.academicYear}
            onChange={onAcademicYearChange}
            disabled={!formEditable}
        />
        <Terms
            url="/terms"
            id="term"
            label="Term"
            noValue="No term"
            value={formData.term}
            disabled={!formEditable}
            onChange={onTermChange}
            onReady={onTermReady}
        />
        <InputPrependAppend
            id="name"
            label="Course title"
            type="text"
            placeholder="Descriptive course title"
            value={formData.name}
            disabled={!formEditable}
            onChange={onNameChange}
            error={hasError(validation, ["name"]) ? "You must provide a valid course title" : null}
        />
        <InputPrependAppend
            id="shortName"
            label="Course ID"
            type="text"
            placeholder="COURSE-ID"
            description="*Uppercase numbers and dashes only"
            value={formData.shortName}
            disabled={!formEditable}
            onChange={onShortNameChange}
            error={hasError(validation, ["shortName"]) ? "You must provide a valid course ID" : null}
        />
        <Departments
            id="department"
            url="/faculties"
            value={formData.department}
            disabled={!formEditable}
            onChange={onDepartmentChange}
            onReady={onDepartmentReady}
            error={hasError(validation, ["department"]) ? "You must provide a valid faculty, school or department" : null}
        />
        <DepartmentTemplates
            id="departmentTemplate"
            url="/templates"
            code={formData.department?.templateCode}
            value={formData.departmentTemplate}
            disabled={!formEditable}
            onChange={onDepartmentTemplateChange}
            onReady={onDepartmentTemplateReady}
        />
        <hr />
        <Semesters
            id="semester"
            url="/semesters"
            label="Semester of delivery"
            noValue="Not applicable"
            value={formData.semester}
            disabled={!formEditable}
            onChange={onSemesterChange}
            onReady={onSemesterReady}
            error={hasError(validation, ["semester"]) ? "You must specify a semester" : null}
        />
        <Purposes
            url="/coursepurposes"
            id="purpose"
            label="What will your course be used for?"
            value={formData.purpose}
            disabled={!formEditable}
            onChange={onPurposeChange}
            onReady={onPurposeReady}
            error={hasError(validation, ["purpose"]) ? "You must specify a course use" : null}
        />
        <PurposeOther
            id="purposeOther"
            label={<strong>*Please specify other use</strong>}
            placeholder="Other"
            show={formData.purpose?.furtherDetailRequired}
            value={formData.purposeOther}
            disabled={!formEditable}
            onChange={onPurposeOtherChange}
            error={hasError(validation, ["purposeOther"]) ? "You must specify a the other course use" : null}
        />
        <hr />
        <UserData
            id="userData"
            label="Will this course record user data such as grades, assessment submissions, participation in discussion boards etc?"
            value={formData.userData}
            disabled={!formEditable}
            onChange={onUserDataChange}
            error={hasError(validation, ["userData"]) ? "You must specify whether this course records user data" : null}
        />
        <Discuss
            id="discuss"
            label="Would you like to discuss the setup of your arbitrary course with a member of Education Services?"
            value={formData.discuss}
            disabled={!formEditable}
            onChange={onDiscussChange}
        />
        <InputPrependAppend
            id="comments"
            label="Do you have any other comments or requirements?"
            type="textarea"
            placeholder="Additional comments"
            value={formData.comments}
            disabled={!formEditable}
            onChange={onCommentsChange}
        />
        <Button type="button" color="danger" onClick={confirmReject} disabled={!formSubmittable}>Reject</Button>
        <span className="float-right">
            <Button type="button" color="secondary" onClick={editMode ? cancelEdit : enableEdit} disabled={!formSubmittable}>{editMode ? "Cancel" : "Edit"}</Button>{" "}
            <Button type="button" color="success" onClick={confirmApprove} disabled={!formSubmittable}>{editMode ? "Submit" : "Approve"}</Button>
        </span>
    </Form>
}

Approval.propTypes = {
    back: PropTypes.string.isRequired
}

export default React.memo(Approval);