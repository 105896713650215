import PropTypes from "prop-types";
import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import ProgrammeCourseForm from "./ProgrammeCourseForm";
import ProgrammeCourseList from "./ProgrammeCourseList";
import ProgrammeCourseAll from "./ProgrammeCourseAll";
import ProgrammeCourseView from "./ProgrammeCourseView";

const ProgrammeCourses = props => <React.Fragment>
    <Switch>
        <Route exact sensitive path={props.path}>
            <ProgrammeCourseList path={props.path} />
        </Route>
        <Route sensitive path={`${props.path}/new`}>
            <ProgrammeCourseForm back={props.path} />
        </Route>
        <Route sensitive path={`${props.path}/view`}>
            <ProgrammeCourseAll back={props.path} />
        </Route>
        <Route sensitive path={`${props.path}/:requestId(\\d+)`}>
            <ProgrammeCourseView back={`${props.path}/view`}/>
        </Route>
        <Route sensitive path={props.path}>
            <Redirect to={props.path} />
        </Route>
        
    </Switch>
</React.Fragment>

ProgrammeCourses.propTypes = {
    path: PropTypes.string.isRequired
}

export default React.memo(ProgrammeCourses);
