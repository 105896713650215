import PropTypes from "prop-types";
import React from "react";
import { BsChevronRight, BsChevronLeft, BsChevronDoubleRight, BsChevronDoubleLeft, BsLink } from "react-icons/bs";
import { Label, FormGroup, Col, Button, ListGroup, ListGroupItem, ButtonGroup, ListGroupItemHeading, Badge } from "reactstrap";

/** 
 * @typedef OptionModel
 * @property {*} value
 * @property {Boolean} selected 
 */

const MultiSelect = props => {
    const { options, value, onChange, disabled, names, links, error, filter, valueComparator = (v1, v2) => v1 === v2 } = props;

    const namesFunction = React.useCallback(value => names ? names(value) : value, [names]);
    const filterFunction = React.useCallback(value => filter ? filter(value) : true, [filter]);
    const linksFunction = React.useCallback(value => links ? links(value) : [], [links]);

    const toggleOption = (setState, index) => () => {
        if (disabled) return;
        setState(prev => {
            const next = [...prev];
            next[index] = { ...next[index], selected: !next[index].selected }
            return next;
        })
    };
    const withKey = eventHandler => e => {
        if (e.key === " " || e.key === "Enter") {
            eventHandler(e);
        }
    }


    const renderLinks = function (value) {
        const linksToRender = linksFunction(value);
        if (linksToRender.length > 0) {
            return <React.Fragment>
                &nbsp;
                <Badge color="info" style={{ fontSize: "11px" }}>
                    <BsLink title="Cohort linked to another programme course" />
                    {linksToRender.map(x => ` ${x} `)}
                </Badge>
            </React.Fragment>;
        }
        else {
            return false;
        }
    }

    const renderOption = (setState) => (option, index) => <ListGroupItem
        disabled={false}
        className="py-1"
        role="option"
        action
        tabIndex={0}
        key={index}
        onKeyDown={withKey(toggleOption(setState, index))}
        onClick={toggleOption(setState, index)}
        active={option.selected}
    >
        {namesFunction(option.value)}
        {renderLinks(option.value)}
    </ListGroupItem>;

    const buildLeftSide = React.useCallback(
        () => {
            return options?.filter(option => !value?.some(value => valueComparator(value, option)))
                      .filter(filterFunction)
                      .map(option => ({ value: option, selected: false })) 
                      || [];
        },
        [options, filterFunction, value, valueComparator]);

    /** @type {[Array<OptionModel>, React.Dispatch<Array<OptionModel>>]} */
    const [leftSide, setLeftSide] = React.useState([]);

    const buildRightSide = React.useCallback(() => {
        return options?.filter(option => value?.some(value => valueComparator(value, option)))
                       .map(option => ({ value: option, selected: false })) 
                       || [];
    }, [options, value, valueComparator]);
    
    const [rightSide, setRightSide] = React.useState([]);

    const initialize = () => {
        setLeftSide(buildLeftSide());
        setRightSide(buildRightSide());
    };
    React.useEffect(initialize, [buildLeftSide, buildRightSide])

    const leftAll = leftSide.map(option => option.value);
    const leftSelected = leftSide.filter(option => option.selected).map(option => option.value);
    const rightAll = rightSide.map(option => option.value);
    const rightNotSelected = rightSide.filter(option => !option.selected).map(option => option.value);


    const addAll = React.useCallback(() => onChange([...leftAll, ...rightAll]), [leftAll, onChange, rightAll]);
    const addSelected = React.useCallback(() => onChange([...leftSelected, ...rightAll]), [leftSelected, onChange, rightAll]);
    const removeAll = React.useCallback(() => onChange([]), [onChange]);
    const removeSelected = React.useCallback(() => onChange(rightNotSelected), [onChange, rightNotSelected]);

    const errorClasses = Boolean(error) ? "border border-danger" : "";


    return <FormGroup id={props.id} row className="justify-content-center">
        {props.label && <Col sm={12}><p>{props.label}</p></Col>}
        <Col sm={5}>
            <ListGroup id={`${props.id}-excluded`} className={errorClasses}>
                <ListGroupItem color="info">
                    <ListGroupItemHeading>
                        Excluded (count: {leftSide?.length}){props.leftAdornment}
                    </ListGroupItemHeading>
                </ListGroupItem>
                <div className="overflow-auto" style={{ maxHeight: "50vh" }}>
                    {leftSide.map(renderOption(setLeftSide))}
                </div>
            </ListGroup>
            {props.excludedLabel && <p class="mt-2">{props.excludedLabel}</p>}
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
            <ButtonGroup id={`${props.id}-controls`} className="w-100" vertical>
                <Button disabled={Boolean(disabled)} className={errorClasses} title="add all from left" aria-label="add all from left" outline type="button" onClick={addAll}><BsChevronDoubleRight /></Button>
                <Button disabled={Boolean(disabled)} className={errorClasses} title="add selected from left" aria-label="add selected from left" outline type="button" onClick={addSelected}><BsChevronRight /></Button>
                <Button disabled={Boolean(disabled)} className={errorClasses} title="remove selected from left" aria-label="remove selected from left" outline type="button" onClick={removeSelected}><BsChevronLeft /></Button>
                <Button disabled={Boolean(disabled)} className={errorClasses} title="remove all from left" aria-label="remove all from left" outline type="button" onClick={removeAll}><BsChevronDoubleLeft /></Button>
            </ButtonGroup>
        </Col>
        <Col sm={5}>
            <ListGroup id={`${props.id}-included`} className={errorClasses}>
                <ListGroupItem color="success">
                    <ListGroupItemHeading>
                        Included (count: {rightSide.length}){props.rightAdornment}
                    </ListGroupItemHeading>
                </ListGroupItem>
                <div className="overflow-auto" style={{ maxHeight: "50vh" }}>
                    {rightSide?.map(renderOption(setRightSide))}
                </div>
            </ListGroup>
            {props.includedLabel && <p class="mt-2">{ props.includedLabel }</p>}
        </Col>
        {error && <Col sm={12}><small className="text-danger" id={`${props.id}-error`}>{error}</small></Col>}
    </FormGroup>
}

MultiSelect.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    filter: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    excludedLabel: PropTypes.string,
    includedLabel: PropTypes.string,
    leftAdornment: PropTypes.node,
    names: PropTypes.func,
    links: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.any),
    rightAdornment: PropTypes.node,
    value: PropTypes.arrayOf(PropTypes.any),
    valueComparator: PropTypes.func
}

export default React.memo(MultiSelect);
