import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router";

/** 
 * @typedef {Object} Notice
 * @property {String} type the alert type
 * @property {*} message the alert message
 */
/** 
 * @callback DangerFunction Show a danger alert
 * @param {*} message message to show
 * @returns {Void}
 */
/** 
 * @callback InfoFunction Show an info alert
 * @param {*} message message to show
 * @returns {Void}
 */
/** 
 * @callback DismissFunction dismiss the current alert
 * @returns {Void}
 */
/** 
 * @typedef {Object} NoticeContext
 * @property {DangerFunction} danger alert danger
 * @property {InfoFunction} info alert info
 * @property {DismissFunction} dismiss dismiss alert
 * @property {Notice} notice the notice object
 */
/** @type {React.Context<NoticeContext>} */
const Context = React.createContext(null);

/**
 * Provider for Notice context
 *
 * @param {Object} props
 * @return {*} 
 */
const Provider = props => {

    const [notice, setNotice] = React.useState(null);

    /** @type {DangerFunction} */
    const danger = React.useCallback(message => {
        setNotice({ type: "danger", message });
    }, [setNotice]);

    /** @type {InfoFunction} */
    const info = React.useCallback(message => {
        setNotice({ type: "info", message });
    }, [setNotice]);

    /** @type {DismissFunction} */
    const dismiss = React.useCallback(() => {
        setNotice(null);
    }, [setNotice]);

    /* Ensure that the alert is cleared when the page changes */
    const location = useLocation();
    React.useEffect(dismiss, [location, dismiss]);

    return <Context.Provider value={{ notice, info, danger, dismiss }}>
        {props.children}
    </Context.Provider>
}

Provider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export { Context }
export default React.memo(Provider);
