import React from "react";
import { Text, StyleSheet } from "react";

const HomePage = () => {

    return <React.Fragment>
        <article>

            <div>
                <h2 style={{color:"#005c85"}}>What is the Blackboard Course Builder?</h2>
                <p>You can use this tool to create and manage different types of Blackboard courses. <a href="https://elearn.soton.ac.uk/knowledge-base/find-and-request-blackboard-courses/" target="_blank">Find out more about the types of Blackboard courses.</a></p>
                <p><b>You do not need to request or manually create most Blackboard courses</b>. Most Blackboard courses are created automatically via data in the Banner student record information system.</p>
                <h2 style={{color:"#005c85"}}>Programme Information Courses</h2>
                <p>These courses hold programme or school level information and communications. Students are auto-enrolled via programme codes. <a href="https://elearn.soton.ac.uk/knowledge-base/blackboard-programme-courses/" target="_blank">Find out more about Programme Information Courses.</a></p>
                <a href="/programme-courses"
                   class="btn btn-default col-sm-3" role="button" style={{ marginTop: "1rem" }}>View courses I have requested</a>
                <br />
                <a href="/programme-courses/view"
                   class="btn btn-default col-sm-3" role="button" style={{ marginTop: "1rem" }}>View all programme courses</a>
                <br />
                <a href="/programme-courses/new"
                   class="btn btn-default col-sm-3" role="button" style={{ marginTop: "1rem" }}>Create programme course</a>
            </div>

            <div>
                <h2 style={{ marginTop: "2rem", color:"#005c85" }}>Custom (arbitrary) courses</h2>
                <p>A custom course does not have an equivalent module code in Banner. Custom courses should be carefully considered. These courses typically require more manual oversight and user management as they cannot be set up in a more automatic way. <a href="https://elearn.soton.ac.uk/knowledge-base/blackboard-request-a-custom-arbitrary-course/" target="_blank">Find out if you need a custom (arbitrary) course.</a></p>
                <a href="/courses" class="btn btn-default col-sm-3" role="button">Create arbitrary course</a>
            </div>
            <div>
                <h2 style={{ marginTop: "2rem", color:"#005c85"}}>Sandbox/Test Courses</h2>
                <p>A sandbox is a course used for training and testing.
                    You can create one personal Ultra Sandbox course,
                    and one Original Sandbox course.</p>
                <p>
                    <a href="/sandbox"
                       class="btn btn-default col-sm-3" role="button" >Create Original test course</a></p>
                <p>
                    <a href="/ultrasandbox"
                        class="btn btn-default col-sm-3" role="button">Create Ultra test course</a></p>
                <p>You can also create a copy of an exemplar Ultra course to see examples 
                    of good practice.
                </p>
                <p>
                    <a href="/ultraexemplar"
                        class="btn btn-default col-sm-3" role="button">Create Ultra exemplar course</a></p>
            </div>
            <div>
                <h2 style={{ marginTop: "2rem", color:"#005c85"}}>Further help</h2>
                <ul>
                    <li>Please see <a href="https://elearn.soton.ac.uk/knowledge-base/bb-navigation/" target="_blank">Getting Started with Blackboard for more information on how to</a>
                        <ul>
                            <li>View courses you are currently enrolled on</li>
                            <li>Understand course naming convention and course availability</li>
                            <li>Request access to modules</li>
                        </ul>
                    </li>
                    <li>To manage the users of a Blackboard course, please also see <a href="https://elearn.soton.ac.uk/article-categories/bb-manage-users/" target="_blank">managing course enrolment</a> and <a href="https://elearn.soton.ac.uk/knowledge-base/blackboard-merged-enrolments/" target="_blank">request merged enrolments.</a></li>
                    <li><a href="https://elearn.soton.ac.uk/knowledge-base/blackboard-request-a-sandbox-course/?hkb-redirect&nonce=eeb8e3b686&check=73tq4&redirect=https%3A%2F%2Fsotonproduction.service-now.com%2Fserviceportal&otype=ht_kb_article&oid=26293&source=widget" target="_blank">Contact ServiceLine</a> - If you can't find the answer, you're looking for please contact ServiceLine and we will be able to help. Please ask for Blackboard support.</li>
                </ul>
            </div>

        </article>

    </React.Fragment >
}

export default React.memo(HomePage);