import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { BsFillPlusCircleFill as Plus, BsCheckCircleFill as Tick, BsFillXCircleFill as Xtick } from "react-icons/bs";
import NoticeArea from "../../../../NoticeArea";
import PaginationTable, { PaginationColumn } from "../../../../PaginationTable";
import { Link } from "react-router-dom";
import { Authentication, ErrorHandler, Waiting } from "../../../../Store";
import { withAbort } from "../../../../../util";
import Joi from "joi";
import DepartmentFilters from "./DepartmentFilters";


// import capitalize from "capitalize";

const buildLink = (path, id, courseType) => {
  switch (courseType) {
    case "PROGRAMME":
      return `${id}`;
    default:
      return null;
  }
};





/** 
 * @typedef DepartmentModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 */

/** 
 * @typedef SchoolModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 * @property {Array<DepartmentModel>} departments 
 */

/** 
 * @typedef FacultyModel
 * @property {String} code
 * @property {String} name
 * @property {String} templateCode
 * @property {Array<SchoolModel>} schools 
 */

const departmentSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional()
});

const schoolSchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional(),
    departments: Joi.array().items(departmentSchema).optional()
});

const facultySchema = Joi.object({
    code: Joi.string().required(),
    name: Joi.string().required(),
    templateCode: Joi.string().allow(null).optional(),
    schools: Joi.array().items(schoolSchema).allow(null).optional(),
});

/**
 * Validate the faculties array
 *
 * @param {*} faculties
 * @return {Array<FacultyModel>} 
 */
const validateFaculties = faculties => {
    const result = Joi.array().min(1).items(facultySchema).required().validate(faculties);
    if (Boolean(result.error)) {
        throw new FormError("Malformed Faculties Response", result);
    }
    return faculties;
}




const ProgrammeCourseAll = (props) => {
  const history = useHistory();

  const { path } = props;

  const handleRequestClick = React.useCallback(
    () => history.push(`${path}/new`),
    [history, path]
  );

    const displayDepartment = function (request) {
        if (request.departmentName) {
            return `${request.schoolName} / ${request.departmentName}`;
        }
        else {
            return request.schoolName;
        }
    }

    const { fetchApi } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { waitFor } = React.useContext(Waiting);

    const [departmentFilter, setDepartmentFilter] = React.useState("");
    const [schoolFilter, setSchoolFilter] = React.useState("");
    const [facultyFilter, setFacultyFilter] = React.useState("");
    const [requestUrl, setRequestUrl] = React.useState("/programme-course-requests");
    const [faculties, setFaculties] = React.useState([]);

    const updateFilters = function (facultyFilter, schoolFilter, departmentFilter) {
        setFacultyFilter(facultyFilter);
        setSchoolFilter(schoolFilter);
        setDepartmentFilter(departmentFilter);
    }

    React.useEffect(() => {
        const newUrl = `/programme-course-requests?departmentFilter=${departmentFilter}&schoolFilter=${schoolFilter}&facultyFilter=${facultyFilter}`;
        setRequestUrl(newUrl);
    }, [departmentFilter, schoolFilter, facultyFilter]);

    const initialize = () => withAbort(signal => {
        const stopWait = waitFor("INIT_DEPARTMENTS");
        fetchApi("/faculties", { method: "GET", signal })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateFaculties)
                        .then(facultiesTemp => {
                            setFaculties(facultiesTemp);
                        })
                } else {
                    throw new FormError("Could not contact Faculties API");
                }
            })
            .catch(handleError)
            .finally(stopWait)
    });
    React.useEffect(initialize, [fetchApi, handleError, waitFor]);


  return (
    <React.Fragment>
        <h1>List all Blackboard Programme courses</h1>
        <p>
            Select <strong>View</strong> to see the programme codes that are linked
            to the course enrolments. To request any changes to an existing course,
            email <a href="serviceline@soton.ac.uk">serviceline@soton.ac.uk</a> and
            include the Blackboard Course ID.
        </p>
        <NoticeArea />

        <DepartmentFilters
            faculties={faculties}
            onChange={updateFilters}/>


    <PaginationTable
        url={requestUrl}
        rowKey={(request) => `${request.courseType}-${request.id}`}
        emptyMessage="No programme course requests"
    >        
        <PaginationColumn cell={(request) => request.courseId}>
          Blackboard Course ID
        </PaginationColumn>
        <PaginationColumn
          cell={(request) => {
            switch (request.state) {
              case 0:
                return (
                  <div>
                    {request.courseName}{" "}
                    <Badge color="info"> Pending approval</Badge>
                  </div>
                );
              default:
                return request.courseName;
            }
          }}
        >
          Course Title
        </PaginationColumn>
        <PaginationColumn
          cell={displayDepartment}>
          Primary Department
        </PaginationColumn>
              <PaginationColumn
                  cell={request =>
                      (request.availability === "Yes" ? <text><Tick style={{ color: 'green', alignItems: 'center' }} aria-label={"Available"} /> Open</text> : <text><Xtick style={{ color: 'red', alignItems: 'center' }} aria-label={"Unavailable"} /> Private</text>)
                  }>
            Availability
        </PaginationColumn>
        <PaginationColumn
          cell={(request) => (
            <Link
              aria-label={"view"}
              to={buildLink(path, request.id, request.courseType)}
            >
              view
            </Link>
          )}
        >
          Enrolments
        </PaginationColumn>
      </PaginationTable>
      <br />
      <span className="float-right">
        <Button color="default" onClick={handleRequestClick}>
          <Plus /> Request a new programme course
        </Button>
      </span>
    </React.Fragment>
  );
};

ProgrammeCourseAll.propTypes = {
  path: PropTypes.string.isRequired,
};

export default React.memo(ProgrammeCourseAll);
